<template>
  <div class="py-sm-10 py5">
    <div class="row">
      <div class="mx-auto col-sm-12 col-md-10 col-lg-10 col-12">
        <v-card class="v-card--no-border v-card--no-bg mb-10">
          <h1 class="font-weight-bold mb-5 text-center">
            {{ $t('Organizations') }}
          </h1>
        </v-card>
      </div>
    </div>

    <v-row class="justify-center">
      <v-col cols="12" md="10" class="mx-auto">
        <div>
          <div class="pb-5">
            <div class="my-bav__table mb-5">
              <v-card>
                <v-card-text>
                  <h2>{{ $t('myBavSearchNFilter') }}</h2>
                </v-card-text>
                <v-card-text class="d-flex align-center flex-wrap pb-0">
                  <v-row class="px-3">
                    <v-col cols="12" md="4" class="px-0">
                      <v-text-field
                        v-model="searchQuery"
                        single-line
                        dense
                        outlined
                        hide-details
                        clearable
                        :label="$t('formPlaceholderSearch')"
                        :placeholder="$t('formPlaceholderSearch')"
                        class="mb-5 me-3"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-data-table
                  :headers="headers"
                  :items="loadedOrganizations"
                  :server-items-length="totalOrganizations"
                  :loading="loadingOrganizations"
                  :footer-props="{
                    'items-per-page-options': [15, 30, 50, 100],
                    'items-per-page-text': $t('itemsPerPage'),
                  }"
                  class="text-no-wrap pointer"
                  @update:items-per-page="setLimit"
                  @click:row="handleRowClick"
                  @pagination="handlePagination"
                >
                  <template #[`header.trending`]>
                    <v-icon size="22">
                      {{ icons.mdiTrendingUp }}
                    </v-icon>
                  </template>

                  <template #[`item.organizationName`]="{ item }">
                    <span class="text-no-wrap th">{{ item.organizationName }}</span>
                  </template>

                  <template #[`item.id`]="{ item }">
                    <span class="text-no-wrap th">{{ item.id }}</span>
                  </template>

                  <template slot="no-data">
                    <div class="my-5">
                      <p>{{ $t('myBavNoResults') }}</p>
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance, watch } from '@vue/composition-api';
import { getOrganizationsList } from '../../api/organizations';

export default {
  name: 'GlobalAdminOrgs',
  setup() {
    const vm = getCurrentInstance().proxy;

    const loadedOrganizations = ref([]);
    const totalOrganizations = ref(0);
    const loadingOrganizations = ref(false);

    const limit = ref(15);
    const offset = ref(0);
    const currentPage = ref(1);
    const searchQuery = ref('');

    const headers = computed(() => {
      return [
        {
          text: vm.$t('Organization name'),
          value: 'organizationName',
          sortable: false,
          class: 'text-uppercase',
        },
        {
          text: vm.$t('ID'),
          value: 'id',
          sortable: false,
          class: 'text-uppercase',
        },
      ];
    });

    const fetchAll = () => {
      loadingOrganizations.value = true;
      getOrganizationsList(offset.value, limit.value, searchQuery.value).then((res) => {
        loadedOrganizations.value = res.data.page;
        loadingOrganizations.value = false;
        totalOrganizations.value = res.data.total;
      });
    };

    const handlePagination = (event) => {
      if (currentPage.value < event.page) offset.value += limit.value;
      if (currentPage.value > event.page) offset.value -= limit.value;

      currentPage.value = event.page;
      fetchAll();
    };

    const setLimit = (v) => {
      limit.value = v;
      currentPage.value = 1;
      offset.value = 0;
      fetchAll();
    };

    const handleRowClick = (org) => {
      vm.$router.push(`organizations/${org.id}`);
    };

    watch(searchQuery, () => {
      fetchAll();
    });

    fetchAll();

    return {
      loadedOrganizations,
      totalOrganizations,
      loadingOrganizations,
      headers,
      searchQuery,
      setLimit,
      handlePagination,
      handleRowClick,
    };
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
